import styled from "@emotion/styled";

const Errors = styled.p
`
    margin: 3px 0 0 0;
    padding: 0;
    color: #e80700;
    text-align: right;
`;

export default Errors;